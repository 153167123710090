"use client";

import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/free-mode";

import {
	Grid,
	A11y,
	Parallax,
	Keyboard,
	Scrollbar,
	Mousewheel,
	Pagination,
	Navigation,
	FreeMode,
} from "swiper/modules";
import { SlideNextIcon, SlidePrevIcon } from "./icons";
import useWindowSize from "@/lib/hooks/useWindowSize";
import { cn } from "@/utils/utils";
import { Device } from "@/types/Shop.type";
import { uniqueId } from "lodash";
import { useAppSelector } from "@/redux/hook";

export type ResponsiveConfig = {
	[key: number]: {
		slidesPerView: number;
		spaceBetween: number;
	};
};

type Props = {
	data: any[];
	itemSlider: (item: any, index: number) => React.ReactNode;
	id?: string;
	className?: string;
	classNameWrapper?: string;
	classNameItemRender?: string;

	width?: number;
	height?: number;
	initialSlide?: number;
	spaceBetween?: string | number;
	slidesPerView?: number | "auto";
	device?: Device;
	centeredSlides?: {
		desktop?: boolean;
		mobile?: boolean;
	};
	centeredSlidesBounds?: boolean;
	centerInsufficientSlides?: boolean;

	speed?: number;
	loop?: boolean;
	rewind?: boolean;
	grabCursor?: boolean;
	breakpoints?: ResponsiveConfig;
	direction?: "horizontal" | "vertical";

	navigation?: boolean;
	// navigationParameters?: {
	//   enabled?: boolean;
	// };
	pagination?: boolean;
	paginationParameters?: {
		type?: "progressbar" | "bullets" | "fraction" | "custom";
		dynamicBullets?: boolean;
		dynamicMainBullets?: number;
		enabled?: boolean;
		clickable?: boolean;
		bulletClass?: string;
		bulletActiveClass?: string;
	};
	scrollbar?: boolean;
	scrollbarParameters?: {
		draggable?: boolean;
		dragSize?: number | "auto";
		snapOnRelease?: boolean;
	};
	keyboard?: boolean;
	keyboardParameters?: {
		enabled?: boolean;
		onlyInViewport?: boolean;

		pageUpDown?: boolean;
	};
	freeMode?: boolean;
	freeModeParameters?: {
		enabled?: boolean;
		minimumVelocity?: number;
		momentum?: boolean;
		momentumBounce?: boolean;
		momentumBounceRatio?: number;
		momentumRatio?: number;
		momentumVelocityRatio?: number;
		sticky?: boolean;
	};
	grid?: boolean;
	gridParameters?: {
		fill?: "row" | "column";
		rows: number;
	};
	mousewheel?: boolean;
	mousewheelParameters?: {
		enabled?: boolean;
		forceToAxis?: boolean;
		invert?: boolean;
		releaseOnEdges?: boolean;
		sensitivity?: number;
	};
	autoplay?: boolean;
	autoplayParameters?: {
		delay?: number;
		disableOnInteraction?: boolean;
		pauseOnMouseEnter?: boolean;
		reverseDirection?: boolean;
		stopOnLastSlide?: boolean;
		waitForTransition?: boolean;
	};

	navigationButton?: {
		className?: string;
		classNameNext?: string;
		classNamePrev?: string;
		iconNext?: React.ReactNode;
		iconPrev?: React.ReactNode;
		speed?: number;
	};

	slidesPerGroup?: number;
	onSlideChange?: ((swiper: SwiperClass) => void) | undefined;

	//
	customPagination?: {
		show: boolean;
		className?: string;
	};
};

export default function Carousel({
	data,
	itemSlider,
	id,
	className,
	classNameWrapper,
	classNameItemRender,
	device,
	width,
	height,
	initialSlide = 0,
	spaceBetween = 0,
	slidesPerView = 1,

	centeredSlides = {
		desktop: false,
		mobile: false,
	},
	centeredSlidesBounds = false,
	centerInsufficientSlides = false,
	slidesPerGroup,
	speed = 300,
	loop = false,
	rewind = false,
	grabCursor = false,
	breakpoints,
	direction = "horizontal",

	navigation = false,
	// navigationParameters = {
	//   enabled: true,
	// },
	pagination = false,
	paginationParameters = {
		type: "bullets",
		dynamicBullets: false,
		dynamicMainBullets: 1,
		enabled: true,
		clickable: true,
	},
	scrollbar = false,
	scrollbarParameters = {
		draggable: false,
		dragSize: "auto",
		snapOnRelease: false,
	},
	keyboard = false,
	keyboardParameters = {
		enabled: true,
		onlyInViewport: true,

		pageUpDown: true,
	},
	freeMode = true,
	freeModeParameters = {
		enabled: true,
		minimumVelocity: 0.02,
		momentum: true,
		momentumBounce: true,
		momentumBounceRatio: 1,
		momentumRatio: 1,
		momentumVelocityRatio: 1,
		sticky: false,
	},
	grid = false,
	gridParameters = {
		fill: "column",
		rows: 1,
	},
	mousewheel = false,
	mousewheelParameters = {
		enabled: true,
		forceToAxis: false,
		invert: false,
		releaseOnEdges: false,
		sensitivity: 1,
	},
	autoplay = false,
	autoplayParameters = {
		delay: 1000,
		disableOnInteraction: true,
		pauseOnMouseEnter: false,
		reverseDirection: false,
		stopOnLastSlide: false,
		waitForTransition: true,
	},

	navigationButton = {
		className: "",
		classNameNext: "",
		classNamePrev: "",
		iconNext: <SlideNextIcon />,
		iconPrev: <SlidePrevIcon />,
		speed: 400,
	},
	onSlideChange,
	//
	customPagination,
}: Props) {
	const sliderRef = useRef<any>(null);
	const preSelector = useMemo(() => uniqueId(), []);
	const nextSelector = useMemo(() => uniqueId(), []);
	const [activeIndex, setActiveIndex] = useState<number>(0);

	const deviceNew =
		device || useAppSelector((state) => state.ShopReducer.device);
	// const windowSize = useWindowSize();
	// const [windowWidth, setWindowWidth] = useState<number>(windowSize.width || 0);

	//functions
	const handlePrev = useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slidePrev();
	}, []);

	const handleNext = useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slideNext();
	}, []);
	const onSlideChanges = (swiper: SwiperClass) => {
		setActiveIndex(swiper.realIndex);
		onSlideChange && onSlideChange(swiper);
	};

	//
	// useEffect(() => {
	// 	setWindowWidth(windowSize.width || 0);
	// }, [windowSize]);

	return (
		<>
			{data.length > 0 && (
				<div id={id} className={cn(`relative`, classNameWrapper)}>
					<Swiper
						className={className}
						ref={sliderRef}
						width={width}
						height={height}
						initialSlide={initialSlide}
						spaceBetween={spaceBetween}
						slidesPerView={slidesPerView}
						centeredSlides={
							deviceNew === "desktop"
								? centeredSlides.desktop
								: centeredSlides.mobile
						}
						centeredSlidesBounds={centeredSlidesBounds}
						centerInsufficientSlides={centerInsufficientSlides}
						speed={speed}
						loop={data.length <= Number(slidesPerView) ? false : loop}
						rewind={rewind}
						allowTouchMove={true}
						grabCursor={grabCursor}
						breakpoints={breakpoints}
						slidesPerGroup={slidesPerGroup}
						direction={direction}
						// navigation={navigation ? navigationParameters : undefined}
						navigation={
							navigation
								? {
										nextEl: `#next-${nextSelector}`,
										prevEl: `#pre-${preSelector}`,
								  }
								: false
						}
						pagination={
							pagination && !customPagination
								? {
										...paginationParameters,

										// bulletClass : " bg-red-500"
								  }
								: undefined
						}
						scrollbar={scrollbar ? scrollbarParameters : undefined}
						keyboard={keyboard ? keyboardParameters : undefined}
						freeMode={freeMode ? freeModeParameters : undefined}
						grid={grid ? gridParameters : undefined}
						mousewheel={mousewheel ? mousewheelParameters : undefined}
						autoplay={autoplay ? autoplayParameters : undefined}
						onSlideChange={onSlideChanges}
						modules={[
							Pagination,
							Navigation,
							Scrollbar,
							Grid,
							Keyboard,
							Mousewheel,
							Parallax,
							A11y,
							FreeMode,
						]}>
						{data.map((item: any, index: number) => (
							<SwiperSlide key={index} className={classNameItemRender}>
								{itemSlider(item, index)}
							</SwiperSlide>
						))}
					</Swiper>
					{navigation && (
						<>
							<button
								aria-label="slidePrev"
								id={`pre-${preSelector}`}
								className={cn("right-0 -ml-10", navigationButton.classNamePrev)}
								onClick={handlePrev}>
								{navigationButton.iconPrev}
							</button>
							<button
								aria-label="slideNext"
								id={`next-${nextSelector}`}
								className={cn("left-0 -mr-10", navigationButton.classNameNext)}
								onClick={handleNext}>
								{navigationButton.iconNext}
							</button>
						</>
					)}
					{customPagination?.show && data.length > 1 && (
						<div
							className={cn(
								"absolute flex gap-[2px] justify-center items-center  w-full ",
								customPagination.className
							)}>
							{data.map((item, index) => (
								<span
									className={cn(
										"rounded-full bg-gray-300 inline-block h-[3px] w-[3px] shrink-0 transition-all duration-200 ease-in ",
										{
											"rounded-sm w-4 bg-gray-400": activeIndex === index,
										}
									)}></span>
							))}
						</div>
					)}
				</div>
			)}
		</>
	);
}
