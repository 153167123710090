import { cn } from "@/utils/utils";

import { Images } from "@/types/Product.type";

import Image from "@/components/ui/Image";
import ImageConstant from "@/common/constants/image";
import StarBlackIcon from "@/components/icons/StarBlackIcon";
import Carousel from "@/components/Carousel";
import Slider from "@/components/Slider";
import LinkElement from "@/components/LinkElement";
import ImageV2 from "@/components/ui/ImageV2";
import { sr } from "date-fns/locale";

interface CardWarperProps {
	children: React.ReactNode;
	className?: string;
}

export type Resize = {
	width: number;
	height: number;
};
export function CardWarper({ children, className }: CardWarperProps) {
	return (
		<div
			className={cn(
				"card_wrapper w-full lg:border-none border border-gray-200 flex flex-col relative  p-[12px] pt-[20px] md:pt-[12px]  md:min-h-[329px] min-h-[342px] h-auto gap-4 md:gap-[12px]",
				className
			)}>
			{children}
		</div>
	);
}

interface CardImageProps {
	src: Images[];
	alt: string;
	resize: Resize;
	classNameImage?: string;
}
export function CardImage({
	classNameImage,
	src,
	resize,
	alt,
}: CardImageProps) {
	return (
		<>
			<ImageV2
				alt={alt}
				width={resize.width}
				height={resize.height}
				src={src[0]?.url || ""}
				className={cn(
					"relative h-[183px] w-[183px] md:h-[169px] md:w-[169px]  object-contain  object-center",
					classNameImage
				)}
				placeholder={"blur"}
				blurDataURL={ImageConstant.BlurDataURL}
			/>
		</>
	);
}

interface CardImagesProps {
	src: Images[];
	alt: string;
	resize: Resize;
	classNameImage?: string;
	collectionHandle?: string;
	handle?: string;
}
export function CardImages({
	classNameImage,
	src,
	resize,
	alt,
	collectionHandle,
	handle,
}: CardImagesProps) {
	if (!src.length) {
		return null;
	}

	if (src.length === 1) {
		return (
			<LinkElement
				prefetch={false}
				href={{
					pathname: `/products/${
						collectionHandle ? collectionHandle + "/" : ""
					}${handle}`,
				}}
				className="w-auto h-auto flex justify-center ">
				<ImageV2
					layout="responsive"
					alt={alt}
					width={resize.width}
					height={resize.height}
					src={src[0]?.url || ""}
					className={cn(
						"relative w-full h-full md:h-[169px] md:w-[169px]  object-contain  object-center",
						classNameImage
					)}
					placeholder={"blur"}
					blurDataURL={ImageConstant.BlurDataURL}
				/>
			</LinkElement>
		);
	}

	return (
		<Carousel
			data={src.slice(0, 5)}
			customPagination={{
				show: true,
				className: "mt-1",
			}}
			itemSlider={(img) => {
				return (
					<div className=" flex  justify-center">
						<LinkElement
							prefetch={false}
							href={{
								pathname: `/products/${
									collectionHandle ? collectionHandle + "/" : ""
								}${handle}`,
							}}
							className="w-auto h-auto flex justify-center ">
							<ImageV2
								layout="responsive"
								alt={alt}
								width={resize.width}
								height={resize.height}
								src={img?.url || ""}
								className={cn(
									"relative w-full h-full md:h-[169px] md:w-[169px]  object-contain  object-center",
									classNameImage
								)}
								placeholder={"blur"}
								blurDataURL={ImageConstant.BlurDataURL}
							/>
						</LinkElement>
					</div>
				);
			}}
			loop
			freeMode={false}></Carousel>
	);
}

interface CardContentProps {
	children: React.ReactNode;
	className?: string;
}
export function CardContent({ children, className }: CardContentProps) {
	return (
		<div className={cn("flex flex-col  gap-[2px] md:gap-1 w-full", className)}>
			{children}
		</div>
	);
}

interface CardBadgeProps {
	children: React.ReactNode;
	className?: string;
	position?: "left" | "right";
}
export function CardBadge({ children, className, position }: CardBadgeProps) {
	const positionClass =
		position === "right"
			? "md:right-[6px] right-[9px]"
			: "md:left-[6px] left-[12px]";
	return (
		<div
			className={cn(
				"absolute md:top-2 top-[12px] z-10",
				positionClass,
				className
			)}>
			{children}
		</div>
	);
}

export function CardRating({ rate }: { rate: number }) {
	return (
		<div className="flex justify-start items-center gap-[2px]">
			{rate > 0 && (
				<>
					<p className="text-[10px] font-[400] text-gray-400">{rate}</p>
					<StarBlackIcon className="md:-translate-y-[1px]" />
				</>
			)}
		</div>
	);
}
